@import "../../../styles/colors.module.scss";


.reverse_spinner {
    position: relative;
    height: 100%;
    width: 100%;
    border: 4px solid transparent;
    border-top-color: $MRI-original-color-1;
    border-left-color: $MRI-original-color-1;
    border-radius: 50%;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }
  
  .reverse_spinner::before {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    content: "";
    border: 4px solid transparent;
    border-top-color: $MRI-original-color-2;
    border-left-color: $MRI-original-color-2;
    border-radius: 50%;
    -webkit-animation: spinBack 1s linear infinite;
    animation: spinBack 1s linear infinite;
  }
  
  @-webkit-keyframes spin {
      from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
  }
  
  @keyframes spin {
      from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
  }
  
  
  @-webkit-keyframes spinBack {
      from {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
    }
    to {
          -webkit-transform: rotate(-720deg);
          transform: rotate(-720deg);
    }
  }
  
  @keyframes spinBack {
    from {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
    }
    to {
          -webkit-transform: rotate(-720deg);
          transform: rotate(-720deg);
    }
  }