.page_container {
    @apply w-auto h-full p-6 m-6 shadow-lg rounded-md bg-white;
}

.link_wrapper {
    @apply underline underline-offset-2;

    &:hover {
        //color: $color-txt-3;
    }
}

.tableHeading{
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 700;
    color: #464B4F;
}

.checkBoxLabel{
    color: #626262;
    font-size: 12px;
    font-weight: 400;
}

.hyperlink:hover{
    color: #0071AD;

}