@import 'src/styles/colors.module.scss';

.card{
    @apply p-8 shadow-md bg-white rounded-md;
}
.scroller{
    overflow-y: scroll;
    max-height: calc(100vh - 180px);
}

.table{
    @apply text-sm w-full;
    thead, tbody{
        size: 14px;
    }
}
.editbtn{
    @apply rounded border-2 text-sm font-semibold px-4 py-1;
}

.readMode{
    @apply w-1/2 sm:px-2 py-2 font-semibold border-b break-words
}

.editMode{
    @apply w-1/2 sm:px-2 py-2 text-left font-normal border-b
}

.planNumberInput{
    @apply mt-3 mr-2;
    width: 29%;
}