@import "src/styles/colors.module.scss";

.card {
  @apply p-8 mb-2 shadow-md bg-white rounded-md text-gray-600;
}
.key {
  @apply w-full sm:px-2.5 pt-3 pb-2.5 mt-2.5 text-left font-normal;
}
.value {
  @apply sm:px-2.5 pt-3 pb-2.5 font-semibold;
  flex-basis: 50%;
}
.btn {
  color: $color-blue-50;
}
.base {
  @apply flex-auto basis-full lg:basis-1/2 sm:px-2.5;
}
.editMode {
  @apply border outline-2 ml-4 mt-3;
  border-color: $color-black-48;
}
.readMode {
  @apply w-full outline-none mt-3 border-none text-left font-semibold;
  color: $color-grey-40
}
