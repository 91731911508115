@import '../../../../../../styles/colors.module.scss';

.card{
    @apply px-8 py-4 m-2 ml-0 shadow-md bg-white rounded-md;
}

.header{
    @apply flex justify-between h-10 items-center;
}

.label{
    @apply basis-full text-left font-normal text-sm;
}

.value{
    @apply basis-full text-left px-4 font-bold text-sm opacity-60;
    color: $color-grey-40;
}

