@import 'styles/colors.module.scss';

.side_nav_container {
    @apply flex-none relative w-52 z-50;
    transition: width 0.2s;
}

.nav_collapsed {
    width: 60px;
}

.section_container {
    width: calc(100% - 60px);
    transition: width 0.2s;
}

.section_container_collapsed {
    width: calc(100% - 208px);
    transition: width 0.2s;
}

.main_container {
    padding-top: 80px;
}

