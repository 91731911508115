@import 'src/styles/colors.module.scss';

.page_container {
    @apply w-full h-full p-6 mt-20;
}

.link_wrapper {
    @apply underline underline-offset-2;

    &:hover {
        //color: $color-txt-3;
    }
}

.lineBreak {
    padding-bottom: 10px;
    border-bottom: 1px solid #d1d5d9;
}

.label {
    @apply text-sm pl-4
}

.labelValueContainer {
    @apply w-1/2 flex justify-between items-center
}