.header {
  @apply flex justify-between border-b h-10 items-center;
}

.label {
  @apply basis-full text-left font-normal text-sm;
}

.value {
  @apply basis-full text-left px-4 font-semibold text-sm;
}

.address {
  @apply px-4 h-8 items-center text-left text-sm font-normal;
}

// section header styles
.section-header {
  @apply text-base not-italic font-bold;
}

//menu lable text {
.menu-label {
  @apply text-sm not-italic font-semibold;
}

.accordian-header {
  @apply text-base not-italic font-bold;
}

@media (max-width: 768px) {
  .accordian-header {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .header{
    height: auto;
    padding: 4px;
  }
}