@import "../../../styles/colors.module.scss";
.tabview > div
{
    padding: 0px !important;
    background-color: $color-black-10 !important;
}

.navBackground
{
    background-color: $color-black-10 !important;
}

.activetab > a{
    font-size: smaller;
    padding-bottom: 0.75rem !important;
    justify-content: center;
    background-color: $color-black-10 !important;
    color: $color-black-95 !important;
    &:focus{
        box-shadow: none !important;
    }
}
