@import 'src/styles/colors.module.scss';

.page_container {
    padding: 80px 30px 30px 30px;
}

.section{
    @apply flex justify-between h-12 items-center;
}
 
.label {
    @apply basis-1/2 text-left font-normal;
}

.value {
    @apply basis-1/2 px-3 text-left font-semibold;
}