@import "../../../styles/colors.module.scss";

.accordion {
    @apply my-2 p-8 bg-white shadow rounded-lg cursor-pointer;
}

.accordionTitle {
    @apply pl-3 w-full flex flex-row justify-between rounded place-items-center;
    background-color: $color-black-25;
    font: $color-black-95;
}

.accordionContent {
    @apply mt-2;
}

.accordionLabel2 {
    @apply pl-5 py-2.5 font-medium text-base;
}

.accordionBtn {
    @apply px-4 py-1 mr-2 border border-solid rounded font-semibold text-sm place-self-center;
    background-color: $color-black-0;
    border-color: $color-black-48;
}

.accordionEye {
    @apply px-4 py-1 mr-2 place-self-center;
}

@media (max-width: 768px) {

    .accordionBtn,
    .accordionLabel2 {
        display: none;
    }

    .accordionTitle>div {
        width: 50%;
    }
}

@media (min-width: 768px) {
    .accordionEye {
        display: none;
    }
}