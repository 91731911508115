@import "../../../styles/colors.module.scss";

.icon_wrapper {
    padding-top: 7px;
    svg path {}
}

.txt_wrapper {
    @apply text-base font-semibold pt-1 not-italic;
}

.header {
    @apply text-2xl font-bold;
}

@each $theme in $themes {
    :global(.#{$theme}) {}
}
