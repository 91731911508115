@import "../../../styles/colors.module.scss";

.input_wrapper {
    @apply w-full p-[9px] pl-9 text-sm text-gray-900 border rounded-lg focus:ring-transparent;
    color: $color-black-80;
    background-color: $color-black-5;
    border-color: $color-black-54;

    &:focus-within {
        border-color: $color-black-54;
    }
}


.icon_wrapper {
    @apply absolute top-[10px] left-3 pointer-events-none;
}

@each $theme in $themes {
    :global(.#{$theme}) {
        // set theme colors

    }
}

.inputWidth {
    width: 300px;
}

@media (max-width: 768px) {
    .inputWidth {
        width: 250px;
    }
}