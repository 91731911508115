.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #f9fafb;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}

.ownerOccupiedCheckbox {
  height: 16px;
  width: 16px;
  border-radius: 1px;
  border: 1px solid #757c82;
}

.width {
  width: inherit;
}

.borderTop {
  border-top: 1px solid #d1d5d9;
}

.verticalLine {
  height: 32px;
  width: 1px;
  background: #d1d5d9;
}

.textContainer {
  @apply flex text-sm h-8 items-center pl-4 pr-4 py-1;
}

.textStyle {
  @apply text-sm font-semibold;
  font-size: 14px;
}

.notesContainer > div {
  overflow-y: scroll;
}
