@import "../../../styles/colors.module.scss";

.input {
    background-color: transparent;
    border-style: none;
    border: none;
    padding: 8px;
  }

.input:hover {
    background-color: $color-black-25;
    cursor: pointer;
  }
.input:focus {
    border-radius: 0.25rem;
}