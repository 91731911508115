.container {
    width: 100%;
    height: 80%;
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width:  100%;
}