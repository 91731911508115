@import '../../../styles/colors.module.scss';

.container {
    position: fixed;
    background: #fff;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width:  100%;

    svg {
        width: 25%;
        height: auto;
    }
}

.progress {
    background: $MRI-original-color-1;
    justify-content: flex-start;
    border-radius: 100px;
    align-items: center;
    position: relative;
    padding: 0 5px;
    display: flex;
    height: 18px;
    width: 20%;
    margin-top: 20px;
}

.progress_value {
    animation: load 10s normal infinite;
    box-shadow: 0 10px 40px -10px #fff;
    border-radius: 100px;
    background: $MRI-original-color-2;
    height: 9px;
    width: 0;
}

@keyframes load {
    0% { width: 0; }
    100% { width: 90%; }
}