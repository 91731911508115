@import "../../../styles/colors.module.scss";

.inlineEditInputCell{
    border-radius: 4px;

    &:hover{
        border: 2px solid #3F83F8 !important;
    }
    &:focus{
        border: none !important;
        box-shadow: 0 0 0 0.2rem rgb(63 131 248 / 1) !important;
        opacity: 1 !important;
        outline: none !important;
    }
}

.checkbox{
    padding: 1rem !important;
}

.checkbox > div > div{
    padding-top: 5px;

    &:hover{
        border-color: rgb(63 131 248 / 1) !important;
    }
    &:focus{
        box-shadow: 0 0 0 0.1rem rgb(63 131 248 / 1) !important;    
    }
    
}

.checkbox > div > div > div, .tbodyStyle > tbody > tr > td > div > div{
    border-radius: 4px !important;
}

.checkbox > div > div > svg, .checkbox > div > div > div > svg{
    height: 14px !important;
    padding-bottom: 4px !important;
}

.theadStyle{
    border-bottom: 1px solid #A1AAB2;
}

.theadStyle > tr > th{
    padding: 0.75rem 1rem !important;
    border: none !important;
    background: #fff !important;
}


.tbodyStyle > tbody > tr > td{
    padding: 12px 16px!important;
    border: none !important;
}

.paginatorStyle{
    border-top: 1px solid #A1AAB2 !important;
    border-bottom-width: 0px !important;
    border-left-width: 0px !important;
    border-right-width: 0px !important;
}

.paginatorStyle > span{
    display: flex !important;
    align-items: center;
}

.paginatorStyle > span > button{
    min-width: 2rem!important;
    height: 2rem!important;
}

.paginatorStyle > div{
    height: 2.25rem!important;
    align-items: center;
}

.paginatorStyle > div > div{
    width: 2rem!important;
    align-items: center;
}

.paginatorDropdown > span{
    padding: 0.25rem 0.75rem !important;
}

.paginatorActiveButtonStyle{
    background: #e1e5f2 !important;
}