@import '../../../../styles/colors.module.scss';

.headerBtn{
    @apply flex py-1 px-4 text-sm font-semibold rounded border border-solid;
    border-color: $color-black-48;
    background-color: $color-black-0;
    color : $color-black-95;
}

.label{
    @apply text-sm font-semibold;
    color : $color-black-95;
}

.inputField{
    @apply rounded border border-solid;
    border-color: $color-black-48;
    background-color: $color-black-0;
    color : $color-black-95;
}