$themes: dark;

//default theme
$color-black-0: #FFFFFF;
$color-black-5: #F9FAFB;
$color-black-8: #E6E9ED;
$color-black-10: #F7F7F7;
$color-black-15: #F5F5F6;
$color-black-20: #F3F4F6;
$color-black-25: #F3F3F3;
$color-black-30: #EBF1F7;
$color-black-35: #E7EAED;
$color-black-40: #E5E7EB;
$color-black-45: #DBDFE3;
$color-black-48: #D1D5DB;
$color-black-49: #D1D5D9;
$color-black-50: #CCD1D9;
$color-black-54: #CCCCCC;
$color-black-55: #C4C8CE;
$color-black-60: #B7BBC1;
$color-black-65: #B3B8BE;
$color-black-68: #AAB2BD;
$color-black-70: #858991;
$color-black-75: #757C82;
$color-black-80: #6B7280;
$color-black-90: #4B5563;
$color-black-95: #464B4F;
$color-black-100: #27333E;
$color-black-110: #1F2A37;
$color-black-115: #191C1E;
$color-black-120: #130C0D;
$color-black-125: #111928;
$color-black-126: #A1AAB2;
$content-backgroud-color:#F4F6F7;


$color-blue-0: #ebf5ff; 
$color-blue-50: #3176e8;
$color-blue-60: #3573ab;
$color-blue-70: #1a56db;

$color-green-30: #bcf0da;
$color-green-80: #05505c;

$color-grey-40: #626262;

$MRI-original-color-1:#024d66;
$MRI-original-color-2: #bad236;
$MRI-original-color-3: #0071AD;
$MRI-original-color-4: #0093DD;

// themes
$colorSet: (

);


@function GetVariable($selectedTheme, $var) {
    $color-name: $selectedTheme + "-" + $var;
    
    @if not map-has-key($colorSet, $color-name) {
        @error "The color `#{$color-name}` is not defined in the color set.";
    }

    @return map-get($colorSet, $color-name);
}
