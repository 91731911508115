@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./styles/colors.module.scss";
@import "./styles/fonts.module.scss";
@import "./styles/texts.module.scss";

body {
  font-family: "Open Sans", "Helvetica", "Arial";
  background-color: $content-backgroud-color;
}

* {
  font-family: "Open Sans", "Helvetica", "Arial";
}

.page-container {
  width: 100%;
  min-height: 100%;
  padding: 32px;
}

.section-title {
  font-weight: 500;
  font-size: 18px;
}

.input-control {
  @apply flex basis-1/2 w-full px-2 py-1.5 text-sm font-semibold rounded h-10;
}

.input-control:hover {
  cursor: text;
}

.input-control:focus {
  @apply ring-blue-500 outline-blue-500;
}

.border-remove {
  @apply border-none;
}

.border-bottom {
  @apply border-b;
}

// default mode
.svg-stroke {
  svg {}
}

.hyperlink {
  @apply underline;

  &:hover {
    color: #0071AD;
  }
}

.grey-btn {
  display: flex;
  height: 36px;
  padding: 0px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #D1D5D9;
  color: #626262;
  font-size: 12px;
  font-weight: 600;
}

.edit-btn {
  @apply rounded border-2 text-sm font-semibold px-4 py-1;
}

.btn-primary {
  @apply text-white rounded-md text-sm border-2 font-semibold px-4 py-1 ml-2;
  background-color: $MRI-original-color-4;
  border-color: $MRI-original-color-4;
}

.spinnerContainer {
  @apply flex bg-gray-900 bg-opacity-50 fixed justify-center items-center z-50 h-auto overflow-y-auto overflow-x-hidden lg:inset-0 lg:h-full cursor-pointer;
}

.text {
  color: #626262;
  font-size: 12px;
  font-weight: 400;
}

.fix-header-wrapper {
  @apply fixed flex h-20 justify-between pl-8 items-center;
  width: 100%;
  padding-right: 228px; // 208 + 32
  transition: padding-right 0.2s;

  &.collapsed {
    padding-right: 92px; // 60 + 32
  }
}

.box-shadow{
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}

.required{
  &::after{
    content: " *";
    color: red;
  }
}

[type="checkbox"],
[type="radio"] {
  accent-color: $MRI-original-color-3;
  @apply w-4 h-4 items-center;

  &:focus {
    outline: none;
    --tw-ring-shadow: none;
  }
}

@each $theme in $themes {
  :global(.#{$theme}) {
    .svg-stroke {
      svg {
        //stroke: GetVariable($theme, color-txt-1);
      }
    }

    .svg-fill {
      svg {
        // fill: GetVariable($theme, color-txt-1);
      }
    }

    .svg-stroke-paths {
      svg>path {
        //stroke: GetVariable($theme, color-txt-1);
      }
    }
  }
}

/* custom scrollbar */

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: $color-black-50;
  border-radius: 8px;
  border: 5px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $color-black-55;
}

// all the input controles css will go here
// button, texbox, checkbox, dropdown, radio, tabs, etc

.transparent-button {
  @apply rounded border-2 text-sm font-semibold px-4 py-1;
}

.secondaryBtn{
  @apply px-4 py-1 mr-2 border-2 border-solid rounded-md font-semibold text-sm place-self-center;
  background-color: $color-black-0;
  border-color: $color-black-48;
}

// menu related changes as per figma
.p-panelmenu .p-panelmenu-header.p-highlight > a {
  border-radius: 6px !important;
  border-radius: 6px !important;
  height: 45px !important;
}

.p-panelmenu .p-panelmenu-header > a {
  height: 45px !important;
}

.p-panelmenu .p-panelmenu-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #E1EFFE !important;
  color: black !important;
}

.p-tabview-ink-bar {
  background-color: $MRI-original-color-3 !important;
}

.p-datatable .p-sortable-column:focus {
  box-shadow: none !important;
}

.p-sortable-column-icon {
  width: 0.75rem !important;
  height: 0.75rem !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: $MRI-original-color-3 !important;
  background: $MRI-original-color-3 !important;
  border-radius: 4px !important;
}

.p-multiselect-header > .p-checkbox > .p-checkbox-box {
  border-radius: 4px !important;
  &::after {
    padding-left: 6rem;
    content: "Select All";
    font-weight: 600;
    font-size: 14px;
    color: black;
    z-index: 0;
    position: absolute;
    width: max-content;
  }
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: none !important;
  border-color: $MRI-original-color-3 !important;
}

.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: none !important;
  border-color: none !important;
}

.p-datatable .p-column-resizer {
  &:hover {
    width: 0rem !important;
    border: 1px solid lightgray !important;
  }
}

.p-column-resizer-helper{
  background: lightgray !important;
}

.p-selectable-row{
  border-bottom: 1px solid $color-black-10 !important;
    &:hover{
        background-color: #E1EFFE !important;
    }

    &:focus{
        outline: none !important;
    }
}

// InputText component related changes
.p-inputtext {
  border-radius: 4px !important;
  &:focus {
    outline: none !important;
    outline-offset: 0 !important;
    box-shadow: none !important;
  }
}

//Media queries

@media (max-width: 768px) {
  .fix-header-wrapper{
      display: flex;
      flex-direction: column;
      margin-top: 20px;
  }
}