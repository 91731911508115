
.itemContainer {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    height: auto;
    color: #737980;
  }
  
  .itemValue {
    white-space: normal;
    word-break: break-word;
  }