@import "../../../styles/colors.module.scss";
.card{
    @apply p-8 mt-2 mr-2 bg-white rounded-lg;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}
.title{
    @apply text-sm font-bold text-base;
    color: $color-black-95;
    font-size: 14px
}
.line{
    @apply px-2.5;
}

.next{
    @apply px-1;
}

.save{
    @apply text-white rounded text-sm border-2 font-semibold px-4 py-1;
    background-color: $MRI-original-color-4;
    border-color: $MRI-original-color-4;
}