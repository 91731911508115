@import 'src/styles/colors.module.scss';
.card{
    @apply p-8 shadow-md bg-white rounded-md;
}
.scroller{
    overflow-y: scroll;
    max-height: calc(100vh - 415px);
}

.table{
    @apply text-sm w-full;
    thead, tbody{
        size: 14px;
    }
}

.editBtn{
    @apply rounded border-2 text-sm font-semibold px-4 py-1 mr-4;
}

.title {
    font-size: 14px;
}
.address{
    @apply h-8 items-center text-left text-sm font-normal;
}