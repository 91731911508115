.badge{
    background: #0071AD;
    width: 24px;
    height: 24px;
}

.overlayPanel{
    &:hover{
        background: #E4E4E4 
    }
}

.textStyle{
    color: #626262;
    font-size: 12px;
}

.overlayRoot{
    --overlayArrowLeft: none !important;
    margin-top: 2px !important;
    border-radius: 4px !important;
}