@import '../../styles/colors.module.scss';

.tab-button-active {
  text-decoration: underline;
  font-weight: bold;
}

.tab-button {
  text-decoration: none;
  font-weight: normal;
}

.container {
  @apply w-1/4 flex justify-center
}

.lineBreak {
  padding-bottom: 10px;
  border-bottom: 1px solid #d1d5d9;
}

.value {
  @apply w-1/2 flex justify-end items-center
}

.text {
  @apply text-sm font-bold;
  color: $color-grey-40 !important;
}

.expenseColumnGroup>div {
  justify-content: center;
}

.expenseColumnGroup {
  background: white !important;
  border-top: none !important;
}

.textStyle {
  @apply uppercase text-sm font-bold;
  color: $color-grey-40 !important;
}

.budgetedActualColumn {
  width: 11.75rem;
  background: $color-black-49 !important
}

.accountColumn {
  width: 20rem;
}

.lotContributionColumns {
  background: $color-black-49 !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.lotContributionFooter {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  background: white !important;
}