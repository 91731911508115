@import '../../../../../../styles/colors.module.scss';

.title{
    @apply font-bold text-base;
    color: $color-black-95;
    font-size: 14px;
}

.section{
    @apply flex basis-full justify-between pt-4 text-sm;
}

.address{
    @apply px-4 h-8 items-center text-left text-sm font-normal;
}

