@import "../../../styles/colors.module.scss";

.content {
  @apply transition rounded absolute top-full mt-4 px-3 py-1.5 whitespace-nowrap;
}

.dark {
  background-color: $color-black-110;
  color: $color-black-5;
}

.light {
  @apply font-semibold border-solid shadow-md;
  background-color: $color-black-0;
  color: $color-black-110;
  border-color: $color-black-8;
}
