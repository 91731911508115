.header {
  @apply flex justify-between items-center border-b h-10;
}
.label {
  @apply basis-full text-left font-normal text-sm;
}
.value {
  @apply basis-full text-left px-4 font-semibold text-sm;
}

@media (max-width: 768px) {
  .header {
    height: auto;
  }
}
