@import "../../../styles/colors.module.scss";


.calendarWrapper{
    @apply p-4
}

.calendarIconWrapper{
    @apply relative bottom-5
}

.calendarIcon{
    @apply absolute inset-y-0 right-0 flex items-center pr-3.5 pointer-events-none
}

.dateInput{
    @apply cursor-pointer text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-10;
    
    padding: 6px 8px;
    border-radius: 4px;
    background: #FFF;
    color: #626262;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    box-shadow: none !important;

    &:focus{
        border: 2px solid #1C64F2 !important;;
    }

}