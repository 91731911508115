@import "../../../styles/colors.module.scss";

.btn_wrapper {
    @apply w-full flex gap-2 items-center justify-between rounded-lg;
    //border: 1px solid $color-bg-4;
    //color: $color-txt-1;
    padding: 4px 6px;

    svg {
        //stroke: $color-txt-1;
    }
}

.btn_label {
    white-space: nowrap;
    overflow: hidden;
    padding: 4px 12px;
    font-size: 14px;
    font-weight: 600;
}

.option_wrapper {
    @apply fixed rounded-lg shadow mt-2 z-40;
    //border: 1px solid $color-bg-4;
    padding: 4px 6px;
    //background-color: $color-bg-1;
}

.option_item {
    font-size: 14px;
    padding: 6px 12px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        //background-color: $color-bg-1-hover;
    }
}
