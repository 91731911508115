@import "src/styles/colors.module.scss";

.page_container {
    padding: 24px;
    min-height: 100%;
    
}

.link_wrapper {
    @apply underline underline-offset-2;

    &:hover {
        //color: $color-txt-3;
    }
}
