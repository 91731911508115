@import 'src/styles/colors.module.scss';

.header{
    @apply flex justify-between border-b;
    border-color: $color-black-126;
}

.fieldLabel{
    @apply basis-full sm:px-3 py-2 text-left;
}

.fieldValue{
    @apply basis-full sm:px-3 py-2 text-right font-semibold;
}