@import "src/styles/colors.module.scss";

@media (min-width: 768px) {
    .page_container {
        overflow-y: scroll;
        max-height: calc(100vh - 180px);
    }

}

.container {
    @apply pl-8 flex flex-wrap flex-grow;
}

@media (max-width: 768px) {
    .editBtnMobile {
        display: none;
    }

    .container {
        flex-flow: column;
        flex-wrap: none;
    }

    .pageContainerMobileView {
        @apply overflow-y-scroll;
        max-height: calc(100vh - 220px);
    }
}