@import 'src/styles/colors.module.scss';

.card{
    @apply mb-2 p-8 shadow-md bg-white rounded-md;
}
.scroller{
    overflow-y: scroll;
    max-height: calc(100vh - 520px);
}
.editbtn{
    @apply rounded border-2 text-sm font-semibold px-4 py-1;
}