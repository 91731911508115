@font-face {
  font-family: "Open Sans";
  font-style: italic;
  src: url("../../public//font/OpenSans-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Helvetica";
  font-style: normal;
  src: url("../../public/font/Helvetica.woff2") format("woff2");
}

@font-face {
  font-family: "Arial";
  font-style: normal;
  src: url("../../public/font/Arial.woff2") format("woff2");
}

$Open-Sans: "Open Sans", "Helvetica", "Arial";

.font-helvetica {
  font-family: "Helvetica", sans-serif;
}

.font-open {
  font-family: $Open-Sans;
}