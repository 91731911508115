@import 'src/styles/colors.module.scss';

.header_container {
    @apply h-20 z-30 items-center pl-8 pr-3 overflow-hidden;
}

.divider {
    //border: 1px solid $color-bg-6;
    height: 30px;
}

@each $theme in $themes {
    :global(.#{$theme}) {
  
      .divider {
        //border-color: GetVariable($theme, color-bg-4);
      }
    
    }
  }

.btnPrimary{
  @apply text-white text-xs not-italic font-semibold rounded;
  background-color: $MRI-original-color-3;
  padding: 0px 16px;
}
.btn{
  @apply text-xs not-italic font-semibold rounded;
  color: $color-black-80;
  background-color: $color-black-48;
  padding: 0px 16px;
}

@media (max-width: 768px) {
  .header_container{
      margin-top: 40px;
      height: 100px;
  }
  .breadCrumbMobileView{
    flex-wrap: wrap;
    overflow-x: scroll;
  }
}